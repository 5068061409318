import axios from 'axios';
import Cookies from 'js-cookie';
import { states, occupations, maritalStatuses } from './constants';
import locales from '../config/localesConfig';

export const isLocal = () => {
    return process.env.REACT_APP_ENV === 'local';
};

export const isProduction = () => {
    return process.env.REACT_APP_ENV === 'production';
};

export const getAccountUrl = () => {
    return process.env.REACT_APP_ACCOUNT_URL;
};

export const getEnrolleeDashboardUrl = () => {
    return process.env[`REACT_APP_ENROLLEE_DASHBOARD_URL`];
};

export const getLocaleConfig = () => {
    try {
        const primaryLocaleCode = process.env.REACT_APP_COUNTRY_LOCALE;

        if (!primaryLocaleCode || !primaryLocaleCode.includes('-')) {
            throw new Error(`Invalid locale code: ${primaryLocaleCode}`);
        }

        const [languageCode, countryCode] = primaryLocaleCode.split('-');
        const countryLocales = locales[countryCode];
        if (!countryLocales || !countryLocales[languageCode]) {
            throw new Error(`Locale not found for code: ${primaryLocaleCode}`);
        }

        return {
            [primaryLocaleCode]: countryLocales[languageCode].language,
            'en-NG': locales['NG']['en'].language || 'English'
        };
    } catch (error) {
        console.error('Error accessing locales configuration:', error);
        return { 'en-NG': 'English' };
    }
};

export const getWebsiteUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'local':
            return 'http://localhost:4000';
        case 'v3test':
        case 'development':
            return 'https://develop.reliancehmo.com';
        case 'staging':
            return 'https://staging.reliancehmo.com';
        case 'daara':
            return 'https://daara.develop.reliancehmo.com';
        case 'daara-staging':
            return 'https://daara.staging.reliancehmo.com';
        default:
            return 'https://reliancehmo.com';
    }
};

export const getSubDomain = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'v3test':
        case 'development':
            return 'develop.';
        case 'staging':
            return 'staging.';
        case 'daara':
            return 'daara.develop.';
        case 'daara-staging':
            return 'daara.staging.';
        default:
            return '';
    }
};

/**
 * Generates the URL for health benefits based on the plan ID.
 *
 * @param {string} planId - The ID of the health plan.
 * @param {string[]} allowedPlanIds - An array of plan IDs that should use the alternative URL.
 * @returns {string} The URL for the health benefits.
 */
export const healthBenefitsUrl = (planId, allowedPlanIds) => {
    if ((allowedPlanIds || []).includes(planId)) {
        return 'https://getreliancehealth.com/alafia/serenity';
    } else {
        return 'https://reliancehmo.com/benefits.html';
    }
};

const RELIANCE_API_VERSION = process.env.REACT_APP_RELIANCE_API_VERSION;

export const BASE_URL_RELIANCE = `${process.env.REACT_APP_BASE_API_URL}${RELIANCE_API_VERSION}`;

export const DOMAIN_COOKIE_NAME = isProduction() ? 'rat' : 'rat_' + process.env.REACT_APP_ENV;

export const DAARA_COOKIE_NAME = 'DAARA';
export const DAARA_REFRESH_TOKEN_NAME =
    process.env.REACT_APP_REFRESH_TOKEN_NAME || 'DAARA_REFRESH_TOKEN';

export const DAARA_TOKEN_PREFIX = process.env.REACT_APP_DAARA_TOKEN_PREFIX || 'ACCOUNT';

export const decodeResourceStatus = (statusCode) => {
    switch (statusCode) {
        case 1:
            return 'active';
        case 2:
            return 'pending';
        case 3:
            return 'deleted';
        case 4:
            return 'suspended';
        case 5:
            return 'expired';
        case 6:
            return 'deactivated';
        case 7:
            return 'delayed';
        default:
            return '';
    }
};

// TODO: USE THIS ANYWHERE YOU USED FREQUENCY
export const decodePaymentFrequency = (freqCode) => {
    switch (freqCode) {
        case 1:
            return 'Daily';
        case 2:
            return 'Weekly';
        case 3:
            return 'Monthly';
        case 4:
            return 'Yearly';
        case 5:
            return 'Biennially';
        case 6:
            return 'Endlessly';
        case 7:
            return 'Quarterly';
        default:
            return '';
    }
};

export const getDiscountedPrice = (price, discount) => {
    return price - (discount / 100) * price;
};

export const setCookie = (name, value, exDays, domain, path, sameSite, secure) => {
    if (!domain) domain = window.location.hostname;
    if (!path) path = '/';
    Cookies.set(name, value, {
        path,
        domain,
        expires: exDays,
        sameSite: sameSite,
        secure: secure
    });
};

export const getCookie = (_name) => {
    return Cookies.get(_name);
};

export const deleteCookie = (name, domain) => {
    Cookies.remove(name, { domain });
};

class UtilityService {
    getOccupations() {
        return Promise.resolve(occupations);
    }

    getStates() {
        return Promise.resolve(states);
    }

    getMaritalStatuses() {
        return Promise.resolve(maritalStatuses);
    }

    getUtilities() {
        return Promise.all([
            this.getOccupations(),
            this.getStates(),
            this.getMaritalStatuses()
        ]).then((responses) => {
            return {
                occupations: responses[0].data,
                states: responses[1].data,
                mStatuses: responses[2].data
            };
        });
    }

    getPlans(type) {
        return axios
            .get(`${BASE_URL_RELIANCE}/products${type ? `?type=${type}` : ''}`)
            .then((response) => {
                return response.data.data;
            });
    }

    getPlanById(id, PLANS) {
        if (!PLANS) {
            this.getPlans().then((plans) => this.getPlanById(id, plans));
        } else {
            return PLANS.filter((plan) => plan.id === id)[0];
        }
    }

    getProviders(productId) {
        return axios
            .get(`${BASE_URL_RELIANCE}/providers?product_id=${productId}`)
            .then((response) => {
                return response.data.data;
            });
    }

    getBanks() {
        return axios.get(`${BASE_URL_RELIANCE}/utility/banks`).then((response) => {
            return response.data.data;
        });
    }

    uploadFile(accessToken, file, uploadType) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_use', uploadType);
        return axios
            .post(`${BASE_URL_RELIANCE}/upload`, formData, {
                header: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return response.data.data.filename;
            })
            .catch((e) => {
                return {
                    message: e.response.data.message || 'An Error Occurred',
                    code: e.response.status
                };
            });
    }
}

export const getSupportedLocales = (position = null) => {
    const localeKeys = Object.keys(getLocaleConfig());
    if (position === null) {
        return localeKeys;
    }
    if (position === 'primary') {
        return localeKeys[0];
    } else if (position === 'secondary' && localeKeys.length > 1) {
        return localeKeys[1];
    }
    return null;
};

export const findLocaleByValue = (searchValue) => {
    const foundEntry = Object.entries(getLocaleConfig()).find(
        ([key, value]) => value.toLowerCase() === searchValue.toLowerCase()
    );
    return foundEntry ? foundEntry : [null, null];
};

export default new UtilityService();
