import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './store/configureStore';
import defaultStore from './store/defaultStore';
import { sessionService } from 'redux-react-session';
import { unregister } from './registerServiceWorker';
import './index.css';
import App from './App';
import './i18n';
import { FeatureFlagProvider } from './context/FeatureFlagsContext';
import LoadingScreen from './components/LoadingScreen';
import userService from './services/userService';

userService.forceLogoutIfQueryPresent();

const store = configureStore(defaultStore);
sessionService.initSessionService(store, { driver: 'LOCALSTORAGE' });

ReactDOM.render(
    <Suspense fallback={<LoadingScreen />}>
        <Provider store={store}>
            <FeatureFlagProvider>
                <App />
            </FeatureFlagProvider>
        </Provider>
    </Suspense>,
    document.getElementById('root')
);

unregister();
